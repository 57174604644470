@import '~antd/dist/antd.css';

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout-background {
  background: #fff;
}

.site-layout-sub-header-background {
  background: white;
}
